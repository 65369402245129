import React from "react"
import styles from "./footer.module.scss"
import Container from "../container/container"
import insta from "../../images/socials/insta.svg"
import linkedin from "../../images/socials/linkedin.svg"
import twitter from "../../images/socials/twitter.svg"
import fb from "../../images/socials/fb.svg"
import { Link } from "gatsby"

const Footer = props => {
  return (
    <div className={styles.footer}>
      <Container>
        <div
          className={styles.footer__wrap}
          data-sal="fade"
          data-sal-duration="1000">
          <div className={styles.footer__wrap__item}>
            <img src={props.logo} />
            <p>
              15 Seaton Road, Thorpe Astley, <br />
              Leicester, LE3 3SU <br />
              <a href="tel:01163183771">+44 (0) 116 3183771</a> <br />
              <a href="mailto:steve@onecreative.me.uk">
                steve@onecreative.me.uk
              </a>
            </p>
          </div>
          <div className={styles.footer__wrap__item}>
            <p className={styles.footer__wrap__item__heading}>Quick Links</p>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/services">Services</Link>
            <Link to="/case-studies">Portfolio</Link>
            <Link to="/logo-lounge">Logo lounge</Link>
            <Link to="/contact">Contact</Link>
          </div>
          <div className={styles.footer__wrap__item}>
            <p className={styles.footer__wrap__item__heading}>Services</p>
            <Link to="/services">Logo Design</Link>
            <Link to="/services">Branding</Link>
            <Link to="/services">Brochure Design</Link>
            <Link to="/services">Social Media Banners</Link>
            <Link to="/services">Signage & Leaflets</Link>
          </div>
          <div className={styles.footer__wrap__item}>
            <div styles={styles.footer__wrap__socials}>
              <div className={styles.footer__wrap__socials__icons}>
                <a
                  href="https://uk.linkedin.com/in/steve-walker-99b29329"
                  target="_blank">
                  <img src={linkedin} />
                </a>
                <a
                  href="https://www.instagram.com/onecreative_1/"
                  target="_blank">
                  <img src={insta} />
                </a>
                <a href="https://twitter.com/one_creative" target="_blank">
                  <img src={twitter} />
                </a>
                <a
                  href="https://www.facebook.com/onecreative.me.uk"
                  target="_blank">
                  <img src={fb} />
                </a>
              </div>
              <p className={styles.footer__by}>
                &copy; 2021 One Creative Design Agency.
              </p>
              <p className={styles.footer__by}>
                Developed by <Link to="https://webizzy.co.uk/">Webizzy</Link>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
